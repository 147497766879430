<template>
    <div class="profile_lifecycles profile_notes">
        <div class="title">
            <h3>{{timeSpan.value}}</h3>
            <div class="right_side">
                <div class="form_grp m-0 p-0">
                    <div class="group_item">
                        <div class="field_wpr">
                            <Multiselect v-model="timeSpan.value" v-bind="timeSpan" class="small"></Multiselect>
                        </div>
                    </div>
                </div>
                <div class="custom_range" :class="{active: timeSpan.value === 'Custom Range'}">
                    <h4>Custom Range</h4>
                    <ul>
                        <li>
                            <label for="">Start Date</label>
                            <datepicker v-model="startDate" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                        </li>
                        <li>
                            <label for="">End Date</label>
                            <datepicker v-model="endDate" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ul class="lifecycles_tags">
            <li @click="tempSelect($event)" class="active">
                <img src="@/assets/images/form.svg">Form
            </li>
            <li @click="tempSelect($event)" class="active">
                <img src="@/assets/images/playbook.svg">Plybooks
            </li>
            <li @click="tempSelect($event)" class="active">
                <img src="@/assets/images/mail.svg">E-mail
            </li>
            <li @click="tempSelect($event)" class="active">
                <img src="@/assets/images/journey.svg">Journey
            </li>
        </ul>
        <div class="lifecycle">
            <ul>
                <li>
                    <span class="icon"><i class="far fa-envelope"></i></span>
                    <h4>Emails<label class="tag failed">Failed</label><span>9h ago</span></h4>
                    <div class="info">The message with a subject of Johnny shared a file was failed <span>March 9, 2022 | 10:30 AM</span></div>
                </li>
                <li>
                    <span class="icon"><i class="far fa-clipboard"></i></span>
                    <h4>Notes<label class="tag">Add</label><span>10h ago</span></h4>
                    <div class="info">The message with a subject of Johnny shared a file was failed <span>March 9, 2022 | 9:15 AM</span></div>
                </li>
                <li>
                    <span class="icon"><i class="far fa-file-alt"></i></span>
                    <h4>Forms<label class="tag success">Enabled</label><span>11h ago</span></h4>
                    <div class="info">Test form 2 was successfully Enable <span>March 9, 2022 | 8:30 AM</span></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
export default{
    name: 'ProfileLifecycles',
    components:{
        Multiselect
    },
    data(){
        return{
            timeSpan: {
                mode: 'single',
                value: 'Last 30 Days',
                options: [
                    { value: 'Today', label: 'Today' },
                    { value: 'Last 7 Days', label: 'Last 7 Days' },
                    { value: 'Last 14 Days', label: 'Last 14 Days' },
                    { value: 'Last 30 Days', label: 'Last 30 Days' },
                    { value: 'This Month', label: 'This Month' },
                    { value: 'Last Month', label: 'Last Month' },
                    { value: 'This Year', label: 'This Year' },
                    { value: 'All Time', label: 'All Time' },
                    { value: 'Custom Range', label: 'Custom Range' }
                ]
            },
        }
    },
    methods:{
        tempSelect(e){
            let temp = e.currentTarget;
            let allTemp = temp.closest('.lifecycles_tags').querySelectorAll('li');
            for(let i = 0; i < allTemp.length; i++){
                allTemp[i].classList.remove('active');
            }
            if(temp.classList.contains('active')){
                temp.classList.remove('active');
            }else{
                temp.classList.add('active');
            }
        }
    }
}
</script>
